import React, { useState, useEffect } from "react"
import { Auth } from "aws-amplify"
import { useForm } from "react-hook-form"
import "twin.macro"
import Button from "../components/button"
import { navigate } from "gatsby"
import PasswordChecklist from "../components/passwordChecklist"
import AccountLayout from "../components/accountLayout"
import SEO from '../components/seo'

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();


const NewPassword = ( {location} ) => {

	const { register, errors, watch, handleSubmit } = useForm();
	const watchPassword = watch("password")
	const [newPasswordErr, setNewPasswordErr] = useState()

	const email = location && location.state && location.state.email ? location.state.email : null;

	useEffect( () => {
		if (!email) {
			navigate('/forgot')
		}
	})

	const onSubmit = (data) => {
		Auth.forgotPasswordSubmit(email, data.code, data.password)
		.then(data => {
			navigate('/login')
		})
		.catch(err => {
			console.log(err)
			setNewPasswordErr(err.message)
		});
	}

	const newPasswordForm = email && <>
		<SEO title="New Password"/>
		<AccountLayout onSubmit={handleSubmit(onSubmit)} message={'Reset your file.io password'} instructions={
			<div tw="">A verification code was sent to {location.state.email}. Please enter it below and create a new password to continue.</div>
		}>
			<label htmlFor="code">Enter the verification code</label>
			<input
				name="code"
				type="text"
				placeholder="Verification Code"
				ref={register({
					required: true,
					pattern: /^[0-9]+$/
				})}
			></input>
			{errors.code && <div className="error">Please enter a numeric verification code</div>}
			<label htmlFor="password">Create a new password</label>
			<input 
				type="password"
				name="password"
				placeholder="Password"
				ref={register({
					required:true,
					validate: value => schema.validate(value)
				})}/>
				{watchPassword && !schema.validate(watchPassword) && <PasswordChecklist tw="mb-4" schema={schema} password={watchPassword}/>}
				{newPasswordErr && <div className="error">{newPasswordErr}</div>}
			<Button tw="w-full mt-2" type="submit" special={true}>Save New Password</Button>
		</AccountLayout>
	</>

	return email ? newPasswordForm : <div></div>

}

export default NewPassword