import React, { useContext } from "react"
import tw from "twin.macro"
import UiStoreContext from '../contexts/uiStoreContext'
import { observer } from 'mobx-react-lite'
import LogoDefault from "../svgs/logo-default.svg"

const AccountLayout = observer( (props, ref) => {

	const uiStore = useContext(UiStoreContext)

	return <div tw="h-full bg-white"><form ref={ref} {...props} css={{
		...tw`mb-8`,
		'select, input' : {
			...tw`block border border-gray-400 w-full p-3 rounded-md mb-4 bg-transparent`
		},
		label : {
			...tw`w-full pl-1 pb-1`
		},
		'.error' : {
			...tw`-mt-2 pl-1 mb-4 w-full text-red`
		}
	}}>
		<div tw="max-w-md mx-auto flex-1 flex flex-col items-center justify-center px-6 pb-12 text-gray-600">
			<LogoDefault tw="h-12 my-8"/>
			{uiStore.selectedPlan && <h1 tw="text-4xl mb-8 text-black">{`${uiStore.selectedPlan.name} Plan`}</h1>}
			{props.message && <h2 tw="mb-8 text-2xl text-center">{props.message}</h2>}
			{props.instructions && <h3 tw="mb-8 text-lg text-center mx-1">{props.instructions}</h3>}
			{props.children}
		</div>
	</form></div>
}, {forwardRef : true})

export default AccountLayout